<template>
  <v-select label="name" placeholder="Search" :filterable="false" :options="options" @search="onSearch" @open="onOpen" @input="onChange($event)">
    <template slot="no-options">
      No options
    </template>
    <template slot="selected-option" slot-scope="option">
      <div class="selected d-center">
        {{ option.name }}
      </div>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select';
import {mapActions} from "vuex";
import lodash from 'lodash'
export default {
name: "AjaxSelect",
  components: {
    vSelect,
    lodash
  },
  data() {
    return {
      selected: null,
      options:[],
      params: {
        search: "",
        sort: "_id",
        dir: "desc",
        page: 1,
        limit: 25
      },
      hasMore: true,
    }
  },
  created(){
    this.fetchProductDiscounts()
  },
  methods: {
    ...mapActions("storeProducts", [
      "fetchProducts",
    ]),
    onChange(event) {
      this.selected = event
      this.$emit('onProductAdded',event)
    },
    async onOpen () {
      await this.$nextTick(); // Wait for the DOM to update
      const dropdownMenu = document.querySelector(".vs__dropdown-menu");
      if (dropdownMenu) {
        dropdownMenu.addEventListener("scroll", this.handleScroll);
      }
      // if (this.hasNextPage) {
      //   await this.$nextTick();
      //   this.observer.observe(this.$refs.load)
      // }
    },
    onSearch(search, loading) {
      if(search.length) {
        loading(true);
        this.params.search = search
        this.search(loading, this);
      }
    },
    fetchProductDiscounts() {
      this.fetchProducts(this.params)
        .then(result => {
          this.options = result.data.data.docs
        })
        .catch(err => console.log(err));
    },  
    search: _.debounce((loading, vm) => {
      vm.fetchProducts(vm.params)
        .then(result => {
          vm.options =result.data.data.docs
          loading(false);
        })
        .catch(err => loading(false));
    }, 350),
    async fetchMoreProducts() {
      const dropdownMenu = document.querySelector(".vs__dropdown-menu");
      const currentScrollPosition = dropdownMenu.scrollTop; // Save the current scroll position

      this.params.page++;
      try {
        const result = await this.fetchProducts(this.params);
        const newOptions = result.data.data.docs;

        if (newOptions.length < this.params.limit) {
          this.hasMore = false; // No more products available
        }
        this.options = [...this.options, ...newOptions];

        this.$nextTick(() => {
          dropdownMenu.scrollTop = currentScrollPosition; // Restore the scroll position
        });
      } catch (error) {
        console.error("Error fetching more products:", error);
      }
    },
    addScrollListener() {
      const dropdownMenu = document.querySelector(".vs__dropdown-menu");
      if (dropdownMenu) {
        dropdownMenu.addEventListener("scroll", this.handleScroll);
      }
    },
    removeScrollListener() {
      const dropdownMenu = document.querySelector(".vs__dropdown-menu");
      if (dropdownMenu) {
        dropdownMenu.removeEventListener("scroll", this.handleScroll);
      }
    },
    handleScroll(event) {
      const dropdownMenu = event.target;
      if (
        dropdownMenu.scrollTop + dropdownMenu.clientHeight >=
          dropdownMenu.scrollHeight - 10 &&
        this.hasMore
      ) {
        this.fetchMoreProducts();
      }
    },
  },
  watch: {
    selected(value) {
      if(!value) {
        this.params.search = ""
        this.fetchProductDiscounts()
      }
    }
  },
  beforeDestroy() {
    this.removeScrollListener();
  },
}
</script>

<style scoped>

</style>
